import { Box } from "@mui/material";
import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AnimatedContainer, Container, FullHeightBox } from "./boxes";

function Home() {

  const location = useLocation()
  


  return (
    // <div style={{ ...styles.div }}>
    //   <Card style={styles.Card}>
    //     {/* <Card.Body style={{ ...styles.CardBody, opacity, transform: opacity === 1 ? "translate(-50%,-50%)" : "translate(-50%,-100%)", transition: "all 1s linear" }}>
    //       <h1 style={styles.h1}>Wehear</h1>
    //     </Card.Body> */}
    //   </Card>
    // </div>
    <>
      <FullHeightBox >
        <Container >
          <AnimatedContainer  key={location.key} timeout={1000} in={true}>

            <Box sx={{height:"100%",width:"100%",display:"flex"}}>
              <Outlet />

            </Box>
          </AnimatedContainer>
        </Container>
      </FullHeightBox>
    </>
  );
}

export default Home;
