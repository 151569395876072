import React, { useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import popupImage from "../traslatorPopup.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import microphone from "../microphone.png";
import innerEllipse from "../innerEllipse.png";
import outerEllipse from "../outerEllipse.png";
import useUser from "../store/hooks/useUser";
import useLangCodes from "../store/hooks/useLangCodes";
import axios from "axios";
import { Autocomplete, Box, IconButton, Paper, Typography } from "@mui/material";
import { aligncenter, center } from "../theme-mui/common";
import { ColoredBgBox, CustomInput, CustomInputSmall, InnerContainer, MicBox, MicBoxOuter, ShadowedBox } from "./boxes";
import { HelpOutline, Mic, MicOffOutlined, MicOutlined, QuestionMark, SwapHoriz, VolumeUp } from "@mui/icons-material";
import InfoPopUp from "./InfoPopUp";

function Translator() {


  const audio = new Audio()


  const [user, setUser] = useUser()
  const [languages, languageCodes, languageCodesForTrans] = useLangCodes()


  const [isOpen, setIsOpen] = useState(true);

  const [transcript, setTranscript] = useState('');
  const [translatedScript, setTranslatedScript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const [isInReverseMode, setIsInReverseMode] = useState(false);

  const API_KEY = 'AIzaSyAXXBifGRhRLnYq72SfgHYcCPWpCTNV_lM'


  const [listeningLang, setListeningLang] = useState(user.translatedLang)
  const [speakingLang, setSpeakingLang] = useState(user.translatedLang)

  useEffect(() => {
    setListeningLang(user.translatedLang)

  }, [user.translatedLang])

  useEffect(() => {
    setSpeakingLang(user.userLang)
  }, [user.userLang])

  const speak = async (textToSpeak, languageCode) => {
    setIsSpeaking(true)
    const url = "https://texttospeech.googleapis.com/v1/text:synthesize?key=" + API_KEY;
    await axios({
      url, headers: { 'Content-Type': 'application/json' }, method: "POST", data: {
        input: {
          text: textToSpeak
        },
        voice: {
          languageCode: languageCode,
          ssmlGender: 'NEUTRAL'
        },
        audioConfig: {
          audioEncoding: 'MP3',
          speakingRate: 1,
          pitch: 1
        }
      }
    }).then((response) => {

      const audioUrl = "data:audio/mpeg;base64,"
      audio.src = audioUrl + response.data.audioContent
      audio.play().then(() => {
        setIsSpeaking(false)
      })

    })
    console.log('speak')
  }

  const getKeyCode = useCallback(e => {
    if (e.key !== undefined) {
      return e.key;
    } else if (e.keyIdentifier !== undefined) {
      return e.keyIdentifier;
    } else if (e.keyCode !== undefined) {
      return e.keyCode;
    }
  }, [])

  const changeLanguage = useCallback(async (sourceLangCode, convertedLanguageCode, text) => {

    const url = "https://translation.googleapis.com/language/translate/v2?key=" + API_KEY + "&q=" + text + "&target=" + convertedLanguageCode + "&source=" + sourceLangCode + "&format=text";
    try {
      await axios({ url }).then((response) => {
        if (response.data.data && Array.isArray(response.data.data.translations))
          setTranslatedScript(response.data.data.translations.map((item => item.translatedText)).join(''))
        speak(response.data.data.translations.map((item => item.translatedText)).join(''), convertedLanguageCode)
      })


    } catch (e) {
      alert(e.message)
    }




  }, [setTranslatedScript])

  useEffect(() => {
    if (isListening && transcript != '' && translatedScript != '') {
      setTranscript('')
      setTranslatedScript('')
    }
  }, [isListening])

  let value = false

  useEffect(() => {

    window.addEventListener('keydown', (e) => {
      e.preventDefault()

      if (getKeyCode(e) === 'MediaPlayPause') {
        setIsListening(!value)
        value = !value
      }
    })
    return () => window.removeEventListener('keydown', (e) => {
      e.preventDefault()

    })
  }, [])

  useEffect(() => {


    let recognition = null

    if (isListening) {

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
      if (typeof SpeechRecognition === "undefined") {
        alert('Your browser is not supporting it')
      } else {


        const userLang = !isInReverseMode ? languageCodes[user.userLang] : languageCodes[user.translatedLang]
        setSpeakingLang(isInReverseMode ? user.translatedLang : user.userLang)
        setListeningLang(isInReverseMode ? user.userLang : user.translatedLang)
        recognition = new SpeechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.lang = userLang

        recognition.onresult = (event) => {

          setTranscript(
            Array.from(event.results)
              .map(result => result[0].transcript)
              .join('')
          );
        };
        recognition.start();
      }
    } else {
      if (recognition)
        recognition.stop();
    }

    return () => {
      if (recognition)
        recognition.stop();
    };
  }, [isListening, user.userLang, isInReverseMode, user.translatedLang]);

  useEffect(() => {
    if (isInReverseMode)
      setIsListening(true)

  }, [isInReverseMode])
  useEffect(() => {
    if (!isListening)
      setIsInReverseMode(false)

  }, [isListening])

  useEffect(() => {
    if (!isListening && !isSpeaking && transcript !== '' && translatedScript === '')
      changeLanguage(languageCodesForTrans[speakingLang], languageCodesForTrans[listeningLang], transcript)

  }, [isListening, speakingLang, transcript, isSpeaking, changeLanguage, languageCodesForTrans, listeningLang])



  const handleClose = () => {
    setIsOpen(false);
  };


  const handleListen = () => {
    setIsListening(!isListening);


  };

  return (
    // <div style={styles.div}>
    //   <Card style={styles.Card}>
    //     <Card.Body style={styles.CardBody}>
    //       {isOpen && (
    //         <Card style={styles.popupCard}>
    //           <Card.Body style={styles.popupCardBody}>
    //             <h1 style={styles.h1Popup}>How to speak?</h1>
    //             <img
    //               src={popupImage}
    //               alt="Indicator button"
    //               style={styles.imgPopup}
    //             />
    //             <p style={styles.pPopup}>
    //               <span style={styles.span}>Tap twice on gesture to speak</span>{" "}
    //               in your language, it will translate automatically and display.
    //             </p>
    //             <Button
    //               variant="outline-primary"
    //               type="submit"
    //               style={styles.Button}
    //               onClick={handleClose}
    //             >
    //               Got it
    //             </Button>
    //           </Card.Body>
    //         </Card>
    //       )}


    //       <p style={styles.p}>Experience</p>
    //       <h1 style={styles.h1}>REALTIME TRANSLATOR</h1>
    //       <div style={styles.form}>
    //         <Form.Group className="mb-3" style={styles.field}>

    //           <Form.Select name="translateFromLanguage" style={styles.select} onChange={(e) => setUser({ ...user, userLang: parseInt(e.target.value, 10) })}>
    //             {
    //               languages.map((lang, index) => <option value={index} key={lang} selected={index == speakingLang}>{lang}</option>)
    //             }
    //           </Form.Select>
    //         </Form.Group>
    //         <Form.Group className="mb-3">
    //           <Form.Control value={transcript} disabled as="textarea" name="textToTranslate" rows={3} style={styles.textArea} placeholder="Translated text output here" />
    //         </Form.Group>

    //         <Form.Group className="mb-3" style={styles.field}>
    //           <Form.Select name="translateToLanguage" style={styles.select} onChange={(e) => setUser({ ...user, translatedLang: parseInt(e.target.value, 10) })}>
    //             {
    //               languages.map((lang, index) => <option value={index} key={lang} selected={index == listeningLang}>{lang}</option>)
    //             }
    //           </Form.Select>
    //         </Form.Group>
    //         <Form.Group className="mb-3">
    //           <Form.Control value={translatedScript} disabled as="textarea" name="textToTranslate" rows={3} style={styles.textArea} placeholder="Translated text output here" />
    //         </Form.Group>
    //         {isSpeaking && "Speaking"}
    //         {isListening && "Listening"}
    //         <button disabled={isSpeaking} style={styles.micButton} onClick={() => {
    //           if (!isInReverseMode)
    //             setIsInReverseMode(true)
    //           else
    //             setIsListening(false)
    //         }}>

    //           <img src={innerEllipse} alt="mic blink" style={styles.img} />
    //           <img src={outerEllipse} alt="mic blink" style={isListening ? styles.animatedImg : styles.img} />
    //           <img src={microphone} alt="mic blink" style={styles.img} />
    //         </button>
    //       </div>
    //     </Card.Body>
    //   </Card>
    // </div>
    <>
      <InfoPopUp open={isOpen} handleClose={() => setIsOpen(false)} />
      <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>
        <InnerContainer sx={{ alignItems: "center" }} >
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <IconButton color="dark" onClick={() => setIsOpen(true)}><HelpOutline /></IconButton>
          </Box>
          <Typography align='center' variant="h3" color="grey">Experience</Typography>
          <Typography mb={4} align='center' variant="h2" color="dark">REALTIME TRANSLATOR </Typography>

          <Box sx={{ width: "80%", ...aligncenter }}>
            <Box sx={{ display: "flex", flex: 1 }}>
              <Autocomplete


                disableClearable
                fullWidth
                freeSolo
                id="known_lang"
                value={{ label: languages[user.userLang], value: user.userLang }}
                onChange={(e, newVal) => { setUser({ ...user, userLang: newVal.value }) }}
                options={languages.map((item, index) => ({ label: item, value: index }))}

                renderInput={(params) => <CustomInputSmall    {...params} />}
              />
            </Box>
            <Box ml={2} mr={2} sx={{ ...center }}>
              <SwapHoriz color="primary" />
            </Box>
            <Box sx={{ display: "flex", flex: 1 }}>
              <Autocomplete
                disablePortal
                disableClearable
                fullWidth
                freeSolo
                id="unknown_lang"
                value={{ label: languages[user.translatedLang], value: user.translatedLang }}
                onChange={(e, newVal) => { setUser({ ...user, translatedLang: newVal.value }) }}
                options={languages.map((item, index) => ({ label: item, value: index }))}

                renderInput={(params) => <CustomInputSmall {...params} />}
              />
            </Box>
          </Box>

          <Box sx={{ display: "flex", flex: 1, width: "100%", flexDirection: "column" }} mt={3} mb={3}>
            <ShadowedBox active={isListening} elevation={4} component={"div"} >
              <ColoredBgBox>{languages[speakingLang]} &nbsp;<Mic fontSize="inherit" ml={2} /></ColoredBgBox>
              <Box pt={1} sx={{ display: "flex", flex: 1, width: "100%", flexDirection: "column" }}>{transcript}</Box>
              <Box sx={{ width: "100%", justifyContent: "flex-end", display: "flex" }}> <IconButton onClick={() => { speak(transcript, languageCodesForTrans[speakingLang]) }} disabled={isListening || isSpeaking || transcript=='' || !transcript}><VolumeUp fontSize="inherit" ml={2} /></IconButton> </Box>
            </ShadowedBox>
            <ShadowedBox elevation={4} component={"div"} sx={{ mt: 3, mb: 4 }}>
              <ColoredBgBox>{languages[listeningLang]} &nbsp;<VolumeUp fontSize="inherit" ml={2} /></ColoredBgBox>
              <Box pt={1} sx={{ display: "flex", flex: 1, width: "100%", flexDirection: "column" }}>{translatedScript}</Box>
              <Box sx={{ width: "100%", justifyContent: "flex-end", display: "flex" }}><IconButton onClick={() => { speak(translatedScript, languageCodesForTrans[listeningLang]) }} disabled={isListening || isSpeaking || translatedScript=='' || !translatedScript}><VolumeUp fontSize="inherit" ml={2} /></IconButton></Box>
            </ShadowedBox>
          </Box>
          <Box sx={{ ...center }} mb={3}>
            <MicBoxOuter active={isListening}>
              <MicBox onClick={() => {
                if (!isInReverseMode)
                  setIsInReverseMode(true)
                else
                  setIsListening(false)
              }} variant="contained" >
                {!isListening ? <MicOffOutlined /> : <MicOutlined />}
              </MicBox>
            </MicBoxOuter>
          </Box>

        </InnerContainer>
      </Box>
    </>
  );
}

export default Translator;
