import { Box, Button, Grow, styled } from "@mui/material";


export const CustomButtons = styled(Button)(({ theme, fullScreen }) => ({
   borderRadius:"100px",
   marginBottom:theme.spacing(3),
   textTransform:"capitalize"

}));
export const FullWidthButton = styled(CustomButtons)(({ theme, fullScreen }) => ({
 width:"100%"
 }));