import { createContext, useMemo, useState } from "react"
import { langCode, langCodeForTrans, langName } from "../constants"
import UserDataContext from "./UserDataContext"




const UserDataContextProvider = ({ children }) => {


    const languages = useMemo(() => langName, [])
    const languageCodes = useMemo(() => langCode, [])
    const languageCodesForTrans = useMemo(() => langCodeForTrans, [])

    const [state, setState] = useState({
        name: "",
        phone: "",
        email: "",
        userLang: 0,
        translatedLang:1

    })

console.log(state)


    return <UserDataContext.Provider value={{ user: state, setUser: setState, languages, languageCodes, languageCodesForTrans }}>
        {children}
    </UserDataContext.Provider>
}
export default UserDataContextProvider