import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { aligncenter } from "../theme-mui/common";
import { StepButtons } from "./boxes";
import { CustomButtons } from "./buttons";


function Welcome2() {
    const navigate = useNavigate();



    return (
        <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>
            <Typography mt={3} variant="h1" color="primary">Wehear</Typography>
            <Box sx={{display:"flex",alignItems:"center",flexDirection:"column",flex:1,overflow:"hidden"}} p={3}>
                <Typography mt={3} variant="h2" color="dark">#smart<Typography mt={3} variant="h2" color="primary" sx={{ display: "inline" }}>headphone</Typography></Typography>
                <Typography align="center" mt={3} variant="h5" color="grey">Open ear wireless bone conduction headphones platform. Bone conduction technology delivers music through your cheekbones, ensuring ears remain completely open to hear ambient sounds. It is also ear health-friendly and perfect for long time usage, it doesn’t harm your ears</Typography>
            </Box>
            <Box sx={{display:"flex"}} mb={3}>
                <StepButtons active={true}  />
                <StepButtons  />
                <StepButtons />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", pr: 3 }}><CustomButtons onClick={()=>navigate('/welcome3')} variant="contained">Next <KeyboardArrowRight /> </CustomButtons></Box>
        </Box>
    );
}

export default Welcome2;
