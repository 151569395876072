import React from "react";

import { useNavigate } from "react-router-dom";

import useLangCodes from "../store/hooks/useLangCodes";
import useUser from "../store/hooks/useUser";
import { Autocomplete, Box, Typography } from "@mui/material";
import { aligncenter } from "../theme-mui/common";
import { FullWidthButton } from "./buttons";
import { CustomInput, FormGroup, InnerContainer } from "./boxes";

function LanguageSelect() {

  const navigate = useNavigate();

  const [user, setUser] = useUser()
  const [languages, languageCodes, languageCodesForTrans] = useLangCodes()
  const formSubmit = () => {
    navigate("/translator");
  };
  console.log(user)
  return (
    <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>
      <Box>
        <Typography mt={3} align='center' variant="h2" color="dark">Select Language </Typography>
        <Typography mb={4} align='center' variant="h3" color="grey">you want to translate</Typography>
      </Box>
      <InnerContainer component="form" onSubmit={formSubmit}  >
        <FormGroup >
          <Box mt={3} mb={3}>
            <Typography color="grey" mb={1} variant="h6" align="center">Select Language you know.</Typography>
            <Autocomplete
              
              SelectProps={{ IconComponent: () => null }}
              disableClearable
              id="known_lang"
              value={{ label: languages[user.userLang], value: user.userLang }}
              onChange={(e, newVal) => { setUser({ ...user, userLang: newVal.value }) }}
              options={languages.map((item, index) => ({ label: item, value: index }))}
              fullWidth={true}
              renderInput={(params) => <CustomInput    {...params} SelectProps={{ IconComponent: () => null }}  />}
            />
          </Box>
          <Box mt={3} mb={3}>
            <Typography color="grey" mb={1} variant="h6" align="center">Select Language you translate in</Typography>
            <Autocomplete
              disablePortal
              disableClearable
              id="unknown_lang"
              value={{ label: languages[user.translatedLang], value: user.translatedLang }}
              onChange={(e, newVal) => { setUser({ ...user, translatedLang: newVal.value }) }}
              options={languages.map((item, index) => ({ label: item, value: index }))}
              fullWidth={true}
              renderInput={(params) => <CustomInput {...params} />}
            />
          </Box>
        </FormGroup>
        <FullWidthButton type="submit" variant="contained">Next</FullWidthButton>
      </InnerContainer>
    </Box>
  );
}

export default LanguageSelect;
