import React, { useState } from "react";
import Card from "react-bootstrap/Card";
// import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function Feedback() {
  const styles = {
    div: {
      background: "linear-gradient(to bottom,  #1111AA 0%,#1F53DA 100%)",
      width: "100vw",
      height: "100vh",
      position: "relative",
    },
    Card: {
      position: "absolute",
      textAlign: "center",
      width: "75%",
      height: "80%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      boxShadow: "10px black",
    },
    CardBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      height: "80%",
    },
    h1: {
      fontFamily: "Azonix",
      fontStyle: "normal",
      fontWeight: "600",
      width: "20vw",
      left: "50%",
      transform: "translate(-50%, 0)",
      fontSize: "2vw",
      position: "absolute",
      color: "black",
    },
    Rating: {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    button: {
      position: "absolute",
      top: "90%",
      left: "50%",
      transform: "translate(-50%, 0)",
      width: "18vw",
      height: "7vh",
      borderRadius: "4vh",
      borderColor: "#1F53DA",
      fontSize: "1.1vw",
    },
    textArea: {
      position: "absolute",
      top: "60%",
      left: "50%",
      transform: "translate(-50%, 0)",
      fontSize: "1.5vh",
      borderColor: "#1F53DA",
      width: "18vw",
      height: "15vh",
    },
  };

  // const [rating, setRating] = useState(0);

  // Catch Rating value
  // package link - https://www.npmjs.com/package/react-simple-star-rating

//   const handleRating = (rate: number) => {
//     setRating(rate);

    // other logic
//   };
  // Optinal callback functions
//   const onPointerEnter = () => console.log("Enter");
//   const onPointerLeave = () => console.log("Leave");
//   const onPointerMove = (value: number, index: number) =>
//     console.log(value, index);

  return (
    <div style={styles.div}>
      <Card style={styles.Card}>
        <Card.Body style={styles.CardBody}>
          <h1 style={styles.h1}>How was your experience?</h1>
          <Rating
            // onClick={handleRating}
            // onPointerEnter={onPointerEnter}
            // onPointerLeave={onPointerLeave}
            // onPointerMove={onPointerMove}
            style={styles.Rating}
            SVGstyle={{ display: "inline-block" }}
          />
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              name="feedbackText"
              rows={3}
              style={styles.textArea}
              placeholder="Translated text output here"
            />
          </Form.Group>
          <Button variant="outline-primary" type="submit" style={styles.button}>
            Submit Feedback
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Feedback;
