export const langName = [
    "English", "Hindi", "French", "Malyalam", "Afrikaans", "Azerbaijan", "Indonesian",
    "Malay", "Javanese", "Sundanese", "Catalan", "Czech", "Danish", "German", "Estonian", "Spanish", "Basque", "Filipino",
    "Galician", "Croatian", "Zulu", "Icelandic", "Italian", "Swahili", "Latvian", "Lithuanian", "Hungarian", "Dutch", "Norwegian",
    "Uzbek", "Polish", "Portuguese", "Romanian", "Slovenian", "Slovak", "Finnish", "Swedish", "Vietnamese", "Turkish",
    "Greek", "Bulgarian", "Russian", "Serbian", "Ukrainian", "Georgian", "Armenian", "Hebrew", "Arabic", "Farsi", "Urdu", "Amharic", "Tamil",
    "Bengali", "Cambodia (Khmer)", "Kannada", "Marathi", "Gujarati", "Sinhala", "Telugu", "Nepali", "Lao", "Thai", "Burmese", "Korean", "Japanese", "Chinese (Mandarin)",
    "Odia", "Sanskrit", "Albanian", "Belarusian", "Bosnian", "Cebuano", "Corsican", "Esperanto", "Frisian", "Irish", "Latin", "Mongolian", "Myanmar(Burmese)", "Persian", "Punjabi", "Sindhi"
]

export const langCode = [
    "en", "hi-IN", "fr-FR", "ml-IN", "af-ZA", "az-AZ", "id-ID", "ms-MY", "jv-ID", "su-ID", "ca-ES", "cs-CZ", "da-DK", "de", "et-EE",
    "es", "eu-ES", "fil-PH", "gl-ES", "hr-HR", "zu-ZA", "is-IS", "it-IT", "sw", "lv_LV", "lt-LT", "hu-HU", "nl-NL", "nb-NO", "uz-UZ", "pl-PL", "pt-PT", "ro-RO", "sl-SI", "sk-SK", "fi-FI", "sv-SE", "vi-VN",
    "tr-TR", "el-GR", "bg-BG", "ru-RU", "sr-RS", "uk-UA", "ka-GE", "hy-AM", "he-IL", "ar", "fa-IR", "ur", "am-ET", "ta", "bn", "km-KH", "kn-IN", "mr-IN", "gu-IN", "si-LK",
    "te-IN", "ne-NP", "lo-LA", "th-TH", "my-MM", "ko-KR", "ja-JP", "zh-CN", "or", "sa", "sq", "be", "bs", "ceb", "co", "eo", "fy", "ga", "la", "mn", "my", "fa", "pa", "sd"
]
export const langCodeForTrans = [
    "en", "hi", "fr", "ml", "af", "az", "id", "ms", "jv", "su", "ca", "cs", "da", "de", "et",
    "es", "eu", "fil", "gl", "hr", "zu", "is", "it", "sw", "lv", "lt", "hu", "nl", "nb", "uz", "pl", "pt", "ro", "sl", "sk", "fi", "sv", "vi",
    "tr", "el", "bg", "ru", "sr", "uk", "ka", "hy", "he", "ar", "fa", "ur", "am", "ta", "bn", "km", "kn", "mr", "gu", "si",
    "te", "ne", "lo", "th", "my", "ko", "ja", "zh", "or", "sa", "sq", "be", "bs", "ceb", "co", "eo", "fy", "ga", "la", "mn", "my", "fa", "pa", "sd"
]

