import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../store/hooks/useUser";
import { Box, Typography } from "@mui/material";
import { aligncenter } from "../theme-mui/common";
import { FullWidthButton } from "./buttons";
import { CustomInput, FormGroup, InnerContainer } from "./boxes";

function Register() {
  const [err, setError] = useState('')
  const [user, setUser] = useUser()


  const navigate = useNavigate();

  const formSubmit = (e) => {
    e.preventDefault();

    if (!user.name || user.name.trim() == '') {
      setError('Enter your Name')
      return
    }
    if (!user.email || user.email.trim() == '') {
      setError('Enter your Email')
      return
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email.trim())) {
      setError('Enter Valid Email')
      return
    }
    if (!user.phone || user.phone.trim() == '') {
      setError('Enter your Phone')
      return
    }
    navigate("/languageSelect");
  }
  console.log(user)
  return (

    <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>
      <Box>
        <Typography mt={3} align='center' variant="h3" color="grey">Fill information before</Typography>
        <Typography mb={4} align='center' variant="h2" color="dark">Excellent Experience </Typography>

      </Box>
      {/* 
          <Form style={styles.form} onSubmit={formSubmit}>
            <p style={{ color: 'red', fontSize: "16px", marginBottom: "12px" }}>{err}</p>
            <Form.Group className="mb-3">
              <Form.Control type="text" onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} name="name" placeholder="Name" style={styles.field} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="text" onChange={(e) => setUser({ ...user, phone: e.target.value })} value={user.phone} name="phone" placeholder="Phone No." style={styles.field} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control type="email" onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} name="email" placeholder="Email" style={styles.field} />
            </Form.Group>
            <Button
              variant="outline-primary"
              type="submit"
              style={styles.button}
              className="button"
            >
              Submit and Experience
            </Button>
          </Form> */}
      <InnerContainer component="form" onSubmit={formSubmit}  >
        <Typography mb={4} align='center' variant="h5" color="error">{err}</Typography>
        <FormGroup >
          <CustomInput onChange={(e) => setUser({ ...user, name: e.target.value })} value={user.name} fullWidth={true} label="Name" />
          <Box mt={3} mb={3}>
            <CustomInput onChange={(e) => setUser({ ...user, phone: e.target.value })} value={user.phone} fullWidth={true} label="Phone no." mt={2} />
          </Box>
          <CustomInput onChange={(e) => setUser({ ...user, email: e.target.value })} value={user.email} fullWidth={true} label="Email" />
        </FormGroup>
        <FullWidthButton type="submit" variant="contained">Submit and Experience</FullWidthButton>
      </InnerContainer>
    </Box>
  );
}

export default Register;
