import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import image from "../oxdevice.png"
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CustomButtons } from "./buttons";
import { StepButtons } from "./boxes";
import { aligncenter } from "../theme-mui/common";
import { KeyboardArrowRight } from "@mui/icons-material";
//import { useNavigate } from "react-router-dom";

function Welcome4() {
    const styles = {
        div: {
            background: "linear-gradient(to bottom,  #1111AA 0%,#1F53DA 100%)",
            width: "100vw",
            height: "100vh",
            position: "relative",
        },
        Card: {
            position: "absolute",
            textAlign: "center",
            width: "75%",
            height: "80%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "15px",
            boxShadow: "10px black",
        },
        CardBody: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "75%",
            height: "80%",
        },
        p: {
            position: "absolute",
            top: "150%",
            fontFamily: "Axiforma",
            fontStyle: "normal",
            fontWeight: "100",
            fontSize: "1.5vw",
        },
        span: {
            color: "#1F53DA",
        },
        h1: {
            position: "absolute",
            top: "-10%",
            left: "50%",
            transform: "translate(-50%, 0)",
            fontFamily: "Azonix",
            fontStyle: "normal",
            fontWeight: "400",
            width: "50vw",
            fontSize: "4vw",
            color: "#1F53DA",
        },
        button: {
            position: "absolute",
            top: "90%",
            left: "80%",
            padding: "auto",
            fontFamily: "Axiforma",
            fontStyle: "normal",
            fontSize: "1.6vw",
            borderRadius: "3vh",
            width: "10vw",
            height: "7vh",
            justifyContent: "center",
        },
        text: {
            position: "absolute",
            top: "5%",
            left: "50%",
            width: "50vw",
            transform: "translate(-50%, 0)",
        },
        h2: {
            fontSize: "2.5vw",
        },
        img: {
            position: "absolute",
            width: "20vw",
            height: "40vh",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/register");
    }


    return (
        <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>
            <Typography mt={3} variant="h1" color="primary">Wehear</Typography>

            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", flex: 1, overflow: "hidden" }} p={3}>

                <Box mb={4} sx={{ display: "flex", alignItems: "center", flexDirection: "column", flex: 1, overflow: "hidden" }}>
                    <img src={image} alt="TraslatorImage" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Box>
                <Typography mt={3} variant="h3" color="primary">Long press on power button </Typography>
                <Typography mb={4} variant="h3" color="dark">to turn on the device</Typography>
            </Box>

            <Box mb={3} sx={{ display: "flex" }}>
                <StepButtons />
                <StepButtons />
                <StepButtons active={true} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", pr: 3 }}><CustomButtons onClick={() => navigate('/register')} variant="contained">Start <KeyboardArrowRight /> </CustomButtons></Box>
        </Box>
    );
}

export default Welcome4;
