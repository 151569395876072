export const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

}
export const aligncenter = {
    display: "flex",
    alignItems: "center",
    

}
export const justifyCenter = {
    display: "flex",
    
    justifyContent: "center",

}
