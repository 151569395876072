import { Box, Fade, Grow, IconButton, Input, Paper, styled, TextField } from "@mui/material";
import { center } from "../theme-mui/common";


export const FullHeightBox = styled(Box)(({ theme, fullScreen }) => ({
    height: "100%",
    width: "100%",
    background: "linear-gradient(45deg, #1F53DA 10%, #1111AA 50%,#1F53DA 80%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

}));

export const Container = styled(Box)(({ theme, fullScreen }) => ({
    height: "80%",
    width: "60%",
    maxWidth: "1000px",
    maxHeight: "600px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius * 5,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        maxHeight: "none",
        borderRadius: "0px",

    },


}));

export const AnimatedContainer = styled(Fade)(({ theme, fullScreen }) => ({
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "hidden",


}));

export const InnerContainer = styled(Box)(({ theme, fullScreen }) => ({

    width: "90%",
    display: "flex",
    flexDirection: "column",
    flex: 1


}));

export const FormGroup = styled(Box)(({ theme, fullScreen }) => ({

    display: "flex", flex: 1, flexDirection: "column", width: "100%"


}));


export const StepButtons = styled(Box)(({ theme, active }) => ({
    height: '10px',
    width: active ? "25px" : "10px",
    background: active ? theme.palette.primary.main : 'gray',
    borderRadius: active ? "20px" : "100%",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2)


}));

export const CustomInput = styled(TextField)(({ theme, active }) => ({
    borderRadius: "100px !important",

    'fieldset': {
        borderRadius: "1000px"
    },
    legend: {
        marginLeft: "15px"
    },
    label: {
        marginLeft: "15px"
    }
}));

export const CustomInputSmall = styled(TextField)(({ theme, active }) => ({
    borderRadius: theme.shape.borderRadius * 2,

    background: theme.palette.primary.secondary,
    textAlign: "center",
    "*": {
        color: theme.palette.primary.main,
        padding: "0px !important",
        textAlign: "center",
    },
    padding: "5px ",
    alignItems: "center",
    'fieldset': {
        border: "none"
    },

}));

export const ShadowedBox = styled(Paper)(({ theme, active }) => ({
    borderRadius: theme.shape.borderRadius * 2,

    display: "flex", flex: 1, flexDirection: "column", width: "100%",
    alignItems: "flex-start",

    "*": {
        color: theme.palette.primary.main,
    },
    padding: theme.spacing(3),
    // background:active?"linear-gradient(298.25deg, #EBF0FF -61.16%, #FFFFFF 122.23%)":"unset"
    border:active?"3px solid " + theme.palette.primary.main:"none"

}));
export const ColoredBgBox = styled(Box)(({ theme, active }) => ({
    borderRadius: theme.shape.borderRadius * 2,
    fontSize: theme.typography.subtitle2.fontSize,
    width: "unset",
    display: "flex",
    alignItems: "center",
    background: theme.palette.primary.secondary,
    textAlign: "center",
    "*": {
        color: theme.palette.primary.main,
        textAlign: "center",
    },
    padding: "5px ",
}));

export const MicBox = styled(IconButton)(({ theme, active }) => ({
    borderRadius: "100%",
    fontSize: theme.typography.h2.fontSize,
    width: "50px",
    height: "50px",

    ...center,
    background: theme.palette.primary.main + " !important",


    textAlign: "center",
    "*": {
        color: theme.palette.light.main,
        textAlign: "center",
    },
}));
export const MicBoxOuter = styled(Box)(({ theme, active }) => ({

    width: "60px",
    height: "60px",
    borderRadius: "100%",
    ...center,



    border: "1px solid " + theme.palette.primary.main,
    animation: active ? 'soundanimation 1s linear infinite' : "none"
}));