

import { useContext } from "react"
import UserDataContext from "../UserDataContext"


const useUser = () => {
    const { setUser, user } = useContext(UserDataContext)
    return [user, setUser]
}
export default useUser