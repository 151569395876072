import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function Welcome1() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/welcome2')
    }, 3000)
  }, [])

  return (
    <Typography variant="h1" color="primary">Wehear</Typography>
  );
}

export default Welcome1;
