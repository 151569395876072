
const breakPoint = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1919,
            
        },
    }
}
export default breakPoint