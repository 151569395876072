
const typography = {
    typography: {
              
        h1: {
            fontSize: "80px",
            fontFamily: "Azonix",
            fontStyle: "normal",
            fontWeight: "400",
            '@media (max-width:960px)': {
                fontSize: '40px',
            },
            '@media (max-width:650px)': {
                fontSize: '40px',
            }
        },
        h2: {
            fontSize: "48px",
            '@media (max-width:960px)': {
                fontSize: '24px',
            },
            '@media (max-width:650px)': {
                fontSize: '24px',
            }
        },
        h3: {
           
            fontSize: "32px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:650px)': {
                fontSize: '16px',
            }
        },
        h4: {
            fontSize: "15px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '14px',
            },
            '@media (max-width:650px)': {
                fontSize: '12px',
            }
        },
        h5:{
            fontSize: "28px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '14px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        h6:{
            fontSize: "24px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '12px',
            },
            '@media (max-width:650px)': {
                fontSize: '12px',
            }
        },
        button:{
            fontSize: "16px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '16px',
            },
            '@media (max-width:650px)': {
                fontSize: '14px',
            }
        },
        subtitle2:{
            fontSize: "14px",
            fontWeight:"normal",
            '@media (max-width:960px)': {
                fontSize: '10px',
            },
            '@media (max-width:650px)': {
                fontSize: '10px',
            }
        }
    }
}
export default typography