import React from "react";
import image from "../oxdevice.png"
import { Box, Dialog, DialogContent, Slide, Typography } from "@mui/material";
import { CustomButtons, FullWidthButton } from "./buttons";

import { aligncenter } from "../theme-mui/common";

//import { useNavigate } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function InfoPopUp({ open, handleClose }) {




    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent>
                <Box sx={{ ...aligncenter, flexDirection: "column", justifyContent: "space-between", width: "100%", overflow: "hidden", height: "100%" }}>


                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", flex: 1, overflow: "hidden" }} p={3}>
                        <Typography mt={3} variant="h3" color="dark">How to Speak?</Typography>
                        <Box mb={4} sx={{ display: "flex", alignItems: "center", flexDirection: "column", flex: 1, overflow: "hidden" }}>
                            <img src={image} alt="TraslatorImage" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                        </Box>
                        <Typography mt={3} align="center" variant="h5" color="dark">Tap twice on gesture to speak in </Typography>
                        <Typography mb={4} align="center" variant="h5" color="gray"> your language, it will translate automatically and display.</Typography>
                    </Box>

                    <FullWidthButton variant="contained" onClick={handleClose}>Got It</FullWidthButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default InfoPopUp;
