import { createTheme } from "@mui/material"

const colorTheme = {
    palette: {
        primary: {
            main: 'rgba(31, 83, 218, 1)',
            secondary:'#EBF0FF'

        },
        dark: {
            main: "#000"
        },
        light: {
            main: "#fff",
        },
        grey: {
            main: "rgba(44, 44, 44, 1)",
            

        },
        text:{
            secondary:'rgba(31, 83, 218, 1)'
        }
    }

}

export default colorTheme