import './App.css';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Register from './components/Register';
import Welcome1 from './components/Welcome1';
import Welcome2 from './components/Welcome2';
import Welcome3 from './components/Welcome3';
import LanguageSelect from './components/LanguageSelect';
import Translator from './components/Translator';
import Feedback from './components/Feedback';
import './fonts/stylesheet.css';
import { memo } from 'react';
import { ThemeProvider } from '@mui/system';
import theme from './theme-mui';
import Welcome4 from './components/Welcome4';

function App() {
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />}>
            <Route path="" element={<Navigate to={"welcome1"} />} />
              <Route path="welcome1" element={<Welcome1 />} />
              <Route path="welcome2" element={<Welcome2 />} />
              <Route path="welcome3" element={<Welcome3 />} />
              <Route path="welcome4" element={<Welcome4 />} />
              <Route path="register" element={<Register />} />
              <Route path="languageSelect" element={<LanguageSelect />} />
              <Route path="translator" element={<Translator />} />
              <Route path="*" element={<Navigate to={"welcome1"} />} />
            </Route>
            {/* <Route path="/feedback" element={<Feedback/>} />
       <Route path="/register" element={<Register/>}/>
       <Route path="/welcome1" element={<Welcome1/>}/>
       <Route path="/welcome2" element={<Welcome2/>}/>
       <Route path="/languageSelect" element={<LanguageSelect/>}/>
       <Route path="/translator" element={<Translator/>}/> */}
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default memo(App);
